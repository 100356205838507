@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'furore';
  src: url('./Furore.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.buttontelega{
  display: inline-flex;
  padding: 14px 30px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 30px;
  background: #2F71FF;
}

.furore {
  font-family: 'furore', sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.letterspacing1{
  letter-spacing: 2.72px;
}
.letterspacing2{
  letter-spacing: 3.4px;
}

.onhover:hover .textblackhover {
  color: white!important;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: white;
}
::-webkit-scrollbar-thumb {
  background: #575757;
  border-radius: 20px;
  
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Dropdown-control {
  cursor: pointer;
  font-style: normal!important;
  font-size: 16px!important;
  font-weight: 300;
  font-family: 'furore', sans-serif;
  background-color: transparent!important;
  border: none!important;
  color: #000!important;
  text-transform: uppercase!important;
  padding-right:25px!important;
}
.Dropdown-menu {
  font-style: normal;
  font-size: 16px!important;
  max-height: 300px!important;
  
}
.Dropdown-arrow {
  border-color: #000 transparent transparent!important;
  margin-top: 3px;
 
}
.is-open .Dropdown-arrow {
  border-color: transparent transparent #000!important;
}
